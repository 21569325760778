// import * as bootstrap from 'bootstrap'
//require('bootstrap-icons/font/bootstrap-icons.css');

function hideAutocompleteDataList() {
    autocomplete_data_list_container.style.display = 'none';
}

// Elements needed
const autocomplete_field_mobile_container = document.getElementById("autocomplete-mobile")
const autocomplete_modal_object = document.getElementsByClassName("autocomplete-modal")
const autocomplete_modal_node = document.querySelectorAll(".autocomplete-modal")
const autocomplete_modal_close_node = document.querySelectorAll(".autocomplete-modal .close")[0];
const autocomplete_field = document.getElementById("search-bar")
const autocomplete_hidden_field = document.getElementById("hidden-geo-id")
const autocomplete_data_list_container = document.getElementById("matched")
const autocomplete_field_in_modal = document.getElementById("autocomplete-mobile-input")

async function handleChange(e) {

    function removeAllChildNodes(parent) {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild)
        }
    }

    function generateSecondaryOptionText(parent_names) {
        let text = "";
        for (let i = parent_names.length-1; i>=0; i--) {
            if (text !== "") {
                text = text.concat(', ');
            }
            text = text.concat(parent_names[i])
        }
        return text;
    }

    function generateSpan(className, value) {
        const span = document.createElement("span");
        span.className = className;
        span.appendChild(document.createTextNode(value));
        return span;
    }

    function shouldSendForm(e) {
        return e.target.closest(".search-results__options.auto") !== null;
    }

    function handleClickOption(completeText, id, e) {
        autocomplete_field.value = completeText;
        autocomplete_hidden_field.value = id;
        if (shouldSendForm(e)) {
            document.getElementById("filters-form-bar").submit();
        } else {
            if (autocomplete_field_in_modal != null) {
                autocomplete_field_in_modal.value = completeText;
            }
            removeAllChildNodes(autocomplete_data_list_container);
            handleCloseModal();
        }
    }

    function generateOptionText(name, secondary_option_text) {
        const optionElement = document.createElement("div");
        optionElement.className = "text";
        optionElement.appendChild(generateSpan("principal", name));
        optionElement.appendChild(generateSpan("secondary", secondary_option_text));
        return optionElement;
    }

    function generateOptionElement(name, secondary_option_text, completeText, id) {
        const optionElement = document.createElement("div");
        optionElement.className = "text-suggestion";
        optionElement.appendChild(document.createElement("i"));
        optionElement.appendChild(generateOptionText(name, secondary_option_text));
        optionElement.addEventListener("click", (e) => {
            handleClickOption(completeText, id, e);
        })
        return optionElement;
    }

    const query = e.target.value
    if(!query.length || query.length < 2){
        hideAutocompleteDataList();
        removeAllChildNodes(autocomplete_data_list_container)
        return;
    }
    const input = `/api/geo?q=${encodeURIComponent(query)}`;
    const res = await fetch(input)
    const body = await res.json()
    removeAllChildNodes(autocomplete_data_list_container)

    for (const {id, name, parent_names} of body) {
        let secondary_option_text = "";
        let completeText = name;
        if (parent_names != null && parent_names.length > 0) {
            secondary_option_text = generateSecondaryOptionText(parent_names);
            completeText += ", " + secondary_option_text;
        }
        autocomplete_data_list_container.style.display = 'flex'
        autocomplete_data_list_container.appendChild(generateOptionElement(name, secondary_option_text, completeText, id))
    }
    window.onclick = function(e){
        if (!e.target.matches('.search-results__options')){
            hideAutocompleteDataList();
        }
    }
}

async function handleOpenModal() {
    autocomplete_modal_object[0].classList.add("show");
    autocomplete_field.focus()
    document.body.style.overflow = 'hidden';
}

async function handleCloseModal() {
    if (autocomplete_modal_object != null && autocomplete_modal_object.length > 0) {
        autocomplete_modal_object[0].classList.remove("show");
        document.body.style.overflow = 'auto';
    }
}

function moveModalToBodyIfExist() {
    if (autocomplete_modal_node != null && autocomplete_modal_node.length > 0) {
        document.body.appendChild(autocomplete_modal_node[0]);
    }
}

function addListenerEventForAutocompleteFieldMobile() {
    if (autocomplete_field_mobile_container != null) {
        autocomplete_field_mobile_container.addEventListener('focusin', handleOpenModal)
    }
}

function addListenerAutocompleteModalClose() {
    if (autocomplete_modal_close_node != null) {
        autocomplete_modal_close_node.addEventListener('click', handleCloseModal)
    }
}

hideAutocompleteDataList();
autocomplete_field.addEventListener('keyup', handleChange)
addListenerEventForAutocompleteFieldMobile();
addListenerAutocompleteModalClose();
moveModalToBodyIfExist();
